import { $isAtNodeEnd } from '@lexical/selection';
import { RangeSelection } from 'lexical';

export const getSelectedNode = (selection: RangeSelection) => {
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();

    if (anchorNode === focusNode) {
        return anchorNode;
    }

    if (selection.isBackward()) {
        return $isAtNodeEnd(selection.focus) ? anchorNode : focusNode;
    }

    return $isAtNodeEnd(selection.anchor) ? focusNode : anchorNode;
};
