import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_LOW,
    REDO_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import useTranslation from 'next-translate/useTranslation';

import { ToolbarButton } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

import styles from './UndoRedo.module.scss';

export const UndoRedo = () => {
    const [editor] = useLexicalComposerContext();
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),

            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor]);

    const undo = () => editor.dispatchCommand(UNDO_COMMAND, undefined);
    const redo = () => editor.dispatchCommand(REDO_COMMAND, undefined);

    return (
        <div className={styles.UndoRedo}>
            <ToolbarButton disabled={!canUndo} onClick={undo} aria-label={__translate('undo')}>
                <Icon.arrowCounterClockwise />
            </ToolbarButton>
            <ToolbarButton disabled={!canRedo} onClick={redo} aria-label={__translate('redo')}>
                <Icon.arrowClockwise />
            </ToolbarButton>
        </div>
    );
};
