import { DropdownProvider } from '@admin/molecules/RichEditor/Editor/atoms';

import {
    AlignmentSelector,
    Divider,
    HeadingsSelector,
    InsertLink,
    ListSelector,
    Quote,
    TextFormat,
    TextFormatType,
} from './atoms';

import styles from './ToolbarPlugin.module.scss';

export const ToolbarPlugin = () => {
    return (
        <div className={styles.ToolbarPlugin}>
            <DropdownProvider>
                <HeadingsSelector />
            </DropdownProvider>

            <Divider />

            <TextFormat format={TextFormatType.bold} />
            <TextFormat format={TextFormatType.italic} />
            <TextFormat format={TextFormatType.underline} />

            <Divider />

            <Quote />
            <TextFormat format={TextFormatType.code} />

            <Divider />

            <DropdownProvider>
                <ListSelector />
            </DropdownProvider>
            <DropdownProvider>
                <AlignmentSelector />
            </DropdownProvider>

            <Divider />

            <DropdownProvider>
                <InsertLink />
            </DropdownProvider>
        </div>
    );
};
