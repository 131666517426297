import { AutoLinkPlugin as AutoLink } from '@lexical/react/LexicalAutoLinkPlugin';

import { url } from '@common/regexp';

const MATCHERS = [
    (text: string) => {
        const match = url.exec(text);
        if (match === null) {
            return null;
        }

        const fullMatch = match[0];
        return {
            index: match.index,
            length: fullMatch.length,
            text: fullMatch,
            url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
            attributes: { target: '_blank' },
        };
    },
];

export const AutoLinkPlugin = () => <AutoLink matchers={MATCHERS} />;
