import { ChangeEvent, KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
    $getSelection,
    $isRangeSelection,
    BaseSelection,
    COMMAND_PRIORITY_LOW,
    EditorState,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { DropdownContext } from '@admin/molecules/RichEditor/Editor/atoms';
import { getSelectedNode } from '@admin/molecules/RichEditor/Editor/helpers';
import { ToolbarButton } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { Icon } from '@common/atoms/Icon';
import { email as emailRegex, phone as phoneRegex, url as urlRegex } from '@common/regexp';

import styles from './LinkEditor.module.scss';

const isValidUrl = (value: string) => urlRegex.test(value);

const isValidMailTo = (value: string) =>
    value.startsWith('mailto:') && emailRegex.test(value.split('mailto:')[1]);

const isValidTelTo = (value: string) => value.startsWith('tel:') && phoneRegex.test(value.split('tel:')[1]);

const isEmptyOrDefault = (url: string) => url === '' || url === 'https://';

export const LinkEditor = () => {
    const [editor] = useLexicalComposerContext();
    const { close } = useContext(DropdownContext);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [value, setValue] = useState('https://');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const hiddenRef = useRef<HTMLInputElement | null>(null);

    const setUrlFromSelection = (selection: BaseSelection | null) => {
        if (!$isRangeSelection(selection)) {
            return;
        }

        const node = getSelectedNode(selection);
        const parent = node.getParent();

        if ($isLinkNode(parent)) {
            setValue(parent.getURL());
            return;
        }

        if ($isLinkNode(node)) {
            setValue(node.getURL());
            return;
        }
    };

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();

        setUrlFromSelection(selection);
        return true;
    }, []);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }: { editorState: EditorState }) => {
                editorState.read(updateLinkEditor);
            }),

            editor.registerCommand(SELECTION_CHANGE_COMMAND, updateLinkEditor, COMMAND_PRIORITY_LOW),
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(updateLinkEditor);
    }, [editor, updateLinkEditor]);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setValueInEditor();
            close();
        }

        if (e.key === 'Escape') {
            e.preventDefault();
            close();
        }
    };

    const setValueInEditor = () => {
        if (isValidUrl(value) || isValidTelTo(value) || isValidMailTo(value)) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, value);
            return;
        }

        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    };

    const clear = () => {
        close();
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    };

    useEffect(() => {
        const input = inputRef.current!;
        const hidden = hiddenRef.current!;

        if (input && hidden) input.style.width = `${hidden.offsetWidth + 40}px`;
    }, [value]);

    useEffect(() => {
        if (isEmptyOrDefault(value)) {
            return setIsValid(true);
        }

        setIsValid(isValidUrl(value) || isValidTelTo(value) || isValidMailTo(value));
    }, [value]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setValue(e.target.value.replace(/\s/g, ''));
    };

    return (
        <>
            <div className={styles.LinkEditor}>
                <div className={styles.field}>
                    <input
                        ref={inputRef}
                        type="url"
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onBlur={setValueInEditor}
                        className={isValid ? '' : styles.error}
                    />
                </div>
                <ToolbarButton onClick={clear} disabled={isEmptyOrDefault(value)}>
                    <Icon.trash />
                </ToolbarButton>
            </div>
            <div className={styles.hidden} ref={hiddenRef}>
                {value}
            </div>
        </>
    );
};
