import { ReactNode, useContext } from 'react';

import { DropdownContext } from '.';

import styles from './Dropdown.module.scss';

interface Props {
    children: ReactNode;
}

export const Dropdown = ({ children }: Props) => {
    const { isOpen, close } = useContext(DropdownContext);
    if (!isOpen) return null;

    return (
        <>
            <div className={styles.Dropdown}>
                <div className={styles.arrow}>
                    <svg
                        width="17"
                        height="6"
                        viewBox="0 0 17 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M8.5 0L16.7272 6H0.272758L8.5 0Z" fill="white" />
                    </svg>
                </div>
                <div className={styles.container}>{children}</div>
            </div>
            <div className={styles.backdrop} onClick={close}></div>
        </>
    );
};
