import React, { useContext } from 'react';
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Dropdown, DropdownContext } from 'admin/molecules/RichEditor/Editor/atoms';
import useTranslation from 'next-translate/useTranslation';

import { ToolbarButton, ToolbarList } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { BlockType, BlockTypeContext } from '@admin/molecules/RichEditor/Editor/providers/BlockTypeProvider';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

import styles from './ListSelector.module.scss';

export const ListSelector = () => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const { blockType } = useContext(BlockTypeContext);
    const [editor] = useLexicalComposerContext();
    const { toggle } = useContext(DropdownContext);

    const formatBulletList = () => {
        const command = blockType === BlockType.ul ? REMOVE_LIST_COMMAND : INSERT_UNORDERED_LIST_COMMAND;
        editor.dispatchCommand(command, undefined);

        toggle();
    };

    const formatNumberedList = () => {
        const command = blockType === BlockType.ol ? REMOVE_LIST_COMMAND : INSERT_ORDERED_LIST_COMMAND;
        editor.dispatchCommand(command, undefined);

        toggle();
    };

    return (
        <div className={styles.ListSelector}>
            <ToolbarButton aria-label={__translate('select_list')} onClick={toggle}>
                <Icon.listUl />
                <Icon.chevronDown />
            </ToolbarButton>
            <Dropdown>
                <ToolbarList>
                    <ToolbarButton
                        className={styles.button}
                        onClick={formatBulletList}
                        aria-label={__translate('numbered_list')}
                    >
                        <Icon.listUl />
                        <span className={styles.text}>{__translate('bullet_list')}</span>
                    </ToolbarButton>
                    <ToolbarButton
                        className={styles.button}
                        onClick={formatNumberedList}
                        aria-label={__translate('numbered_list')}
                    >
                        <Icon.listOl />
                        <span className={styles.text}>{__translate('numbered_list')}</span>
                    </ToolbarButton>
                </ToolbarList>
            </Dropdown>
        </div>
    );
};
