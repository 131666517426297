import { FC } from 'react';

import styles from './Switch.module.scss';

export interface Props {
    value: boolean;
    _inputProps: any;
}

export const Switch: FC<Props> = ({ _inputProps, value }) => {
    return (
        <label className={styles.switch}>
            <input {..._inputProps} type="checkbox" checked={value} />
            <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
    );
};
