import { EditorConfig, NodeKey, SerializedLexicalNode } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';

import { $createMatchOverviewNode } from './helpers';

export interface MatchNodeData {
    matchID: string;
    title?: string;
    subTitle?: string;
    isBig?: boolean;
    showDate?: boolean;
}

export const CUSTOM_MATCH_OVERVIEW_NODE: string = 'match-overview';

export class MatchOverviewNode extends CustomNode {
    private static type = CUSTOM_MATCH_OVERVIEW_NODE;
    private __data: MatchNodeData;

    constructor(data: MatchNodeData, key: NodeKey) {
        super(key);
        this.__data = data;
    }

    static override getType(): string {
        return MatchOverviewNode.type;
    }

    static override clone(node: MatchOverviewNode): MatchOverviewNode {
        return new MatchOverviewNode(node.__data, node.__key);
    }

    // TODO: try JSX, this is not possible from the createDom. Investigate if it's possible via a decorator https://github.com/facebook/lexical/discussions/3604
    override createDOM(_config: EditorConfig): HTMLElement {
        const element = document.createElement('div');
        element.className = `${MatchOverviewNode.type}`;
        element.innerText = JSON.stringify(this.__data);

        return element;
    }

    // TODO: change the implementation, so the node is create correctly
    static override importJSON(_serializedNode: SerializedLexicalNode): MatchOverviewNode {
        return $createMatchOverviewNode({ data: { matchID: 'MATCH_ID' } });
    }

    override exportJSON() {
        return {
            ...super.exportJSON(),
            data: this.__data,
            type: MatchOverviewNode.type,
            version: 1,
        };
    }
}
