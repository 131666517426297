import {
    DOMConversion,
    DOMConversionMap,
    DOMConversionOutput,
    NodeKey,
    SerializedTextNode,
    TextNode,
} from 'lexical';

export class ExtendedTextNode extends TextNode {
    constructor(text: string, key?: NodeKey) {
        super(text, key);
    }

    static override getType(): string {
        return 'extended-text';
    }

    static override clone(node: ExtendedTextNode): ExtendedTextNode {
        return new ExtendedTextNode(node.__text, node.__key);
    }

    static override importDOM(): DOMConversionMap | null {
        const importers = TextNode.importDOM();
        return {
            ...importers,
            code: () => ({
                conversion: patchStyleConversion(importers?.code),
                priority: 1,
            }),
            em: () => ({
                conversion: patchStyleConversion(importers?.em),
                priority: 1,
            }),
            span: () => ({
                conversion: patchStyleConversion(importers?.span),
                priority: 1,
            }),
            strong: () => ({
                conversion: patchStyleConversion(importers?.strong),
                priority: 1,
            }),
            sub: () => ({
                conversion: patchStyleConversion(importers?.sub),
                priority: 1,
            }),
            sup: () => ({
                conversion: patchStyleConversion(importers?.sup),
                priority: 1,
            }),
        };
    }

    static override importJSON(serializedNode: SerializedTextNode): TextNode {
        return TextNode.importJSON(serializedNode);
    }

    override isSimpleText() {
        return this.__type === 'extended-text' && this.__mode === 0;
    }

    override exportJSON(): SerializedTextNode {
        return {
            ...super.exportJSON(),
            type: 'extended-text',
            version: 1,
        };
    }
}

function patchStyleConversion(
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
    return (node) => {
        const original = originalDOMConverter?.(node);
        if (!original) {
            return null;
        }
        const originalOutput = original.conversion(node);

        if (!originalOutput) {
            return originalOutput;
        }

        return {
            ...originalOutput,
            forChild: (lexicalNode, parent) => {
                const originalForChild = originalOutput?.forChild ?? ((x) => x);
                const result = originalForChild(lexicalNode, parent);
                return result;
            },
        };
    };
}
