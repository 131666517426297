import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $setBlocksType } from '@lexical/selection';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_NORMAL, createCommand } from 'lexical';

import { $createMatchOverviewNode } from './helpers';
import { MatchNodeData, MatchOverviewNode } from './MatchOverviewNode';

export interface MatchOverviewPluginPayload {
    data: MatchNodeData;
    key?: string;
}

export const INSERT_MATCH_OVERVIEW_COMMAND = createCommand('insertMatchOverview');

export const MatchOverviewPlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    if (!editor.hasNodes([MatchOverviewNode])) {
        throw new Error('MatchOverviewPlugin: MatchOverviewNode not registered on editor');
    }

    const listener = (payload: MatchOverviewPluginPayload) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createMatchOverviewNode(payload));
        }
        return true;
    };

    editor.registerCommand(INSERT_MATCH_OVERVIEW_COMMAND, listener, COMMAND_PRIORITY_NORMAL);
    return null;
};
