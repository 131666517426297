import { useEffect } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';

interface Props {
    onBlur?: (value: string) => void;
}

export const OnBlurPlugin = ({ onBlur }: Props) => {
    const [editor] = useLexicalComposerContext();

    useEffect(
        () =>
            editor.registerCommand(
                BLUR_COMMAND,
                () => {
                    const value = $generateHtmlFromNodes(editor, null);
                    if (onBlur) onBlur(value);
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        [editor, onBlur],
    );

    return null;
};
