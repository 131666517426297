export const theme = {
    code: 'code',
    heading: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
    },
    link: 'link',
    list: {
        ol: 'list-ol',
        ul: 'list-ul',
    },
    ltr: 'ltr',
    paragraph: 'paragraph',
    quote: 'quote',
    rtl: 'rtl',
    text: {
        bold: 'bold',
        code: 'code',
        hashtag: 'hashtag',
        italic: 'italic',
        underline: 'underline',
    },
};
