import { useState } from 'react';

import { FieldType } from '@admin/types/FieldType';

import { HiddenInput } from './Editor/atoms';
import { Editor } from './Editor';

import styles from './RichEditor.module.scss';

interface Props {
    id: string;
    name: string;
    type: FieldType.richEditor;
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
    title?: string;
    placeholder?: string;
    value?: string;
}

export const RichEditor = ({ id, name, onChange, ...rest }: Props) => {
    const [value, setValue] = useState<string>();

    const handleOnChange = (value: string) => {
        setValue(value);
        onChange(value);
    };

    return (
        <div className={styles.RichEditor} data-testid={`rich-editor-${name}`}>
            <Editor {...rest} onChange={handleOnChange} />
            <HiddenInput id={id} name={name} value={value} />
        </div>
    );
};
