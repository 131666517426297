import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from 'lexical';

import { BlockType } from './BlockType';

interface Props {
    children: ReactNode;
}

interface State {
    blockType?: BlockType;
}

export const BlockTypeContext = createContext<State>({});

export const BlockTypeProvider = ({ children }: Props) => {
    const [blockType, setBlockType] = useState<BlockType>();
    const [editor] = useLexicalComposerContext();

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type as BlockType);
                    return;
                }

                const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                setBlockType(type as BlockType);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _newEditor) => {
                    updateToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateToolbar]);

    return <BlockTypeContext.Provider value={{ blockType }}>{children}</BlockTypeContext.Provider>;
};
