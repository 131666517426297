import { $createParagraphNode, ElementNode, NodeKey, RangeSelection } from 'lexical';

export class CustomNode extends ElementNode {
    public override __key: NodeKey;

    constructor(key: NodeKey) {
        super(key);
        this.__key = key;
    }

    override updateDOM(_prevNode: any, _dom: any) {
        return false;
    }

    override insertNewAfter(_selection: RangeSelection, restoreSelection?: boolean) {
        const newBlock = $createParagraphNode();
        const direction = this.getDirection();
        newBlock.setDirection(direction);
        this.insertAfter(newBlock, restoreSelection);
        return newBlock;
    }

    override collapseAtStart(_selection: RangeSelection): boolean {
        const paragraph = $createParagraphNode();
        const children = this.getChildren();
        children.forEach((child) => paragraph.append(child));
        this.replace(paragraph);
        return true;
    }
}
