import { FC } from 'react';

import { Action } from '@admin/interfaces/Action';
import { InputElementProps } from '@admin/molecules/InputElement/InputElement';
import { ActionsUtil } from '@admin/utils/ActionsUtil';
import { Icon, IconOption } from '@common/atoms/Icon';

import styles from './SectionHeader.module.scss';

export interface Props extends InputElementProps {
    title: string;
    actions: Action[];
}

export const SectionHeader: FC<Props> = ({ actions, value, title }) => {
    const action = ActionsUtil.format(actions)[0];
    let ChosenIcon;

    if (action.icon) {
        ChosenIcon = Icon[action.icon];
    } else {
        switch (action.id) {
            case 'delete':
                action.icon = IconOption.xCircle;
                break;
            case 'edit':
                action.icon = IconOption.edit;
                break;
            default:
                action.icon = IconOption.minus;
                break;
        }
        ChosenIcon = Icon[action.icon];
    }

    return (
        <label className={styles.SectionHeader}>
            {title}
            {action && typeof value !== 'undefined' ? (
                <span className={styles.action}>
                    <ChosenIcon
                        key={action.id}
                        onClick={() => {
                            if (action.callback) action.callback(value);
                        }}
                    />
                </span>
            ) : null}
        </label>
    );
};
