import { MatchOverviewNode } from '@admin/molecules/RichEditor/Editor/plugins/MatchOverviewPlugin';
import { MatchOverviewPluginPayload } from '@admin/molecules/RichEditor/Editor/plugins/MatchOverviewPlugin/MatchOverviewPlugin';

export const $createMatchOverviewNode = ({ data, key }: MatchOverviewPluginPayload): MatchOverviewNode => {
    if (!key) {
        throw Error('$createMatchOverviewNode: missing key');
    }

    return new MatchOverviewNode(data, key);
};
