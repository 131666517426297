import { createContext, ReactNode, useState } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
}

export const DropdownContext = createContext<State>({
    isOpen: false,
    open: () => {},
    close: () => {},
    toggle: () => {},
});

export const DropdownProvider = ({ children }: Props) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const open = () => setOpen(() => true);
    const close = () => setOpen(() => false);
    const toggle = () => setOpen((open) => !open);

    return (
        <DropdownContext.Provider value={{ isOpen, open, close, toggle }}>
            {children}
        </DropdownContext.Provider>
    );
};
