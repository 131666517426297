import { BlockType } from '@admin/molecules/RichEditor/Editor/providers/BlockTypeProvider';

export const options: { title: string; value: BlockType }[] = [
    {
        title: 'paragraph',
        value: BlockType.paragraph,
    },
    {
        title: 'heading_1',
        value: BlockType.h1,
    },
    {
        title: 'heading_2',
        value: BlockType.h2,
    },
    {
        title: 'heading_3',
        value: BlockType.h3,
    },
    {
        title: 'heading_4',
        value: BlockType.h4,
    },
    {
        title: 'heading_5',
        value: BlockType.h5,
    },
    {
        title: 'heading_6',
        value: BlockType.h6,
    },
];
