import { ReactNode } from 'react';

import styles from './ToolbarButton.module.scss';

interface Props {
    children: ReactNode;
    onClick: () => void;
    className?: string;
    filled?: boolean;
    disabled?: boolean;
}

export const ToolbarButton = ({
    children,
    onClick,
    className = '',
    filled = false,
    disabled = false,
}: Props) => (
    <button
        type="button"
        disabled={disabled}
        className={`${filled ? styles.filled : ''} ${className} ${styles.ToolbarButton}`}
        onClick={!disabled ? onClick : () => {}}
    >
        {children}
    </button>
);
